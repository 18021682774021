import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _15073e48 = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _65935956 = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _5bc84c1c = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _80aedb0e = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _b2fe3e98 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _095b112c = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _d67ed0c8 = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _ebed8622 = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _004df912 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _8205c08c = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _609af542 = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _ee5073e4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _3c0e03d0 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _9639ae6e = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _0fbb2a36 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _fbd5ab38 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _5964154e = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _2d9fc588 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _01d16cd4 = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _67733506 = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _14180720 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _52a856fd = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _087273d2 = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _a64b70d6 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _aa484c64 = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _a2863154 = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _4e27a95b = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _76d713e6 = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _76e52b67 = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _c37d114c = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _d29e7ed8 = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _f96dbede = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _746e422c = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _294b4202 = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _596ef70b = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _8c557320 = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _34a0b096 = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _d0c94024 = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _1f28cd48 = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _84111826 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _de1ede7e = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _d5b373da = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _3d834db1 = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _1ba05928 = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _53bd2118 = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _750336d2 = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _ddcf7966 = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _2ff97630 = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _fbb394c8 = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _54b3abdd = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _0b58e1d8 = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _283be2c8 = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _323371d0 = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _a9fa5ef0 = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _2785d906 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _1dfd0152 = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _49239155 = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _4415ceda = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _8192f53e = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _4abd1074 = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _cc3f54b2 = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _72d9b8d8 = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _ae81d4b4 = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _6b601e46 = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _63ce511f = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _17e8936e = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _40f8b734 = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _58f8538e = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _560c57b1 = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _6ba0b8b8 = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _4b34c302 = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _e04b1c24 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _255978c0 = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _71c0c91c = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _15073e48,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _65935956,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _5bc84c1c,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _80aedb0e,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _b2fe3e98,
    name: "index___en"
  }, {
    path: "/events",
    component: _095b112c,
    name: "events___de"
  }, {
    path: "/files",
    component: _d67ed0c8,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _ebed8622,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _004df912,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _8205c08c,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _609af542,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _ee5073e4,
    name: "login___de"
  }, {
    path: "/logout",
    component: _3c0e03d0,
    name: "logout___de"
  }, {
    path: "/media",
    component: _9639ae6e,
    name: "media___de"
  }, {
    path: "/orders",
    component: _0fbb2a36,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _fbd5ab38,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _5964154e,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _2d9fc588,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _01d16cd4,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _67733506,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _14180720,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _52a856fd,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _087273d2,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _a64b70d6,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _aa484c64,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _a2863154,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _4e27a95b,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _76d713e6,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _76e52b67,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _15073e48,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _65935956,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _5bc84c1c,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _80aedb0e,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _095b112c,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _d67ed0c8,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _ebed8622,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _004df912,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _8205c08c,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _609af542,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _ee5073e4,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _3c0e03d0,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _9639ae6e,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _0fbb2a36,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _fbd5ab38,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _5964154e,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _2d9fc588,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _c37d114c,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _d29e7ed8,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _f96dbede,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _746e422c,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _294b4202,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _596ef70b,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _8c557320,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _34a0b096,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _d0c94024,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _1f28cd48,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _84111826,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _de1ede7e,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _01d16cd4,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _67733506,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _14180720,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _52a856fd,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _087273d2,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _a64b70d6,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _aa484c64,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _a2863154,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _4e27a95b,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _76d713e6,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _76e52b67,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _c37d114c,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _d29e7ed8,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _f96dbede,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _746e422c,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _294b4202,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _596ef70b,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _8c557320,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _34a0b096,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _d0c94024,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _1f28cd48,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _84111826,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _de1ede7e,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _d5b373da,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _3d834db1,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _1ba05928,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _d5b373da,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _3d834db1,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _1ba05928,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _53bd2118,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _750336d2,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _ddcf7966,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _2ff97630,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _fbb394c8,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _54b3abdd,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _0b58e1d8,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _283be2c8,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _323371d0,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _a9fa5ef0,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _2785d906,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _1dfd0152,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _53bd2118,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _49239155,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _4415ceda,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _750336d2,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _8192f53e,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _4abd1074,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _cc3f54b2,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _72d9b8d8,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _ae81d4b4,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _6b601e46,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _63ce511f,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _17e8936e,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _40f8b734,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _58f8538e,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _ddcf7966,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _2ff97630,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _fbb394c8,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _54b3abdd,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _0b58e1d8,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _283be2c8,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _323371d0,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _a9fa5ef0,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _2785d906,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _1dfd0152,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _560c57b1,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _6ba0b8b8,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _4b34c302,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _e04b1c24,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _49239155,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _4415ceda,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _255978c0,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _71c0c91c,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _8192f53e,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _4abd1074,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _cc3f54b2,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _72d9b8d8,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _ae81d4b4,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _6b601e46,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _63ce511f,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _17e8936e,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _40f8b734,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _58f8538e,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _560c57b1,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _6ba0b8b8,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _4b34c302,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _e04b1c24,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _255978c0,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _71c0c91c,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _b2fe3e98,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
